@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    $color-config: mat.get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
  
    $primary: mat.get-color-from-palette($primary-palette, 700);
    $accent: mat.get-color-from-palette($accent-palette, 700);


    .paral {
        min-height: 200px;
        background-attachment: fixed;
        background-size: cover;
        background-position: 50% 50%;
    }
        
    .para01 {
        background-image: url("/assets/gun-map-768.jpg");
    }
        
    .para02 {
        background-image: url("/assets/cannon_measuring.jpg");
    }


    .img-back {
        opacity: 0.8;
        background-color: $primary;
        padding: 10px;
        margin: 10px;
    }
        
    .img-text {
        color: white;
        opacity:1.0 !important;
        font-weight: bold;
        text-shadow: #444 0 1px 1px;
    }

    .os-avatar {
        background-image: url("../../assets/CLEAN OS LOGO-640.png");
        background-size: cover;
    }
}