
/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;



// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//@include mat-core();


@include mat.core();
@import '../styles/site_theme.scss';

@include mat.all-component-themes($bcp-app-theme);


$primary: mat.get-color-from-palette($bcp-app-primary);
$accent: mat.get-color-from-palette($bcp-app-accent);

html, body {
  height: 100%; 
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.bg-site {
  background-color: $primary;
}
  
.bg-site-dark {
  background-color: $accent;
}

.site {
  color: $primary !important;
}

.hidden {
  display: none
}

.bg-ur {
  background-color: $primary;
}

.fill-remaining-space {
  /*This fills the remaining space, by using flexbox.  
 Every toolbar row uses a flexbox row layout. */
  flex: 1 1 auto;
}

.fab {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  box-shadow: 0 6px 10px 0 #666 !important;

  font-size: 50px !important;
  line-height: 45px !important;
  text-align: center !important;

  position: fixed !important;
  right: 20px !important;
  bottom: 10px !important;
  z-index:2000 !important;

 transition: all 0.1s ease-in-out !important;

 @media (min-width: 768px) {
  bottom: 30px !important;
  right: 30px !important;
 }
}

.fab-text {
   font-size: 30px;
   width: unset !important;
   line-height: .7 !important;
   vertical-align: unset;
}

.fbLeft {
  display: flex;
  justify-content: center;
  box-shadow: 0 6px 10px 0 #666 !important;
  position: fixed !important;
  left: 30px !important;
  bottom: 10px !important;
  z-index:2000 !important;
  background-color: $primary;
}

.fxRow {
  display: flex;
  justify-content: center;
  margin-top: 3px;
  margin-bottom: 3px;
}

.fxCol {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.fxRow-sm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3px;
  margin-bottom: 3px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.m-1 {
  margin: 1px;
}

.m-2 {
  margin: 2px;
}

.m-3 {
  margin: 3px
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.container { 
  
  @media (min-width: 576px) {
    max-width: 560px
  }

  @media (min-width: 768px) {
    max-width:760px;
  }

  @media (min-width: 810px) {
    max-width: 810px;
  }

  @media (min-width: 992px) {
    max-width: 990px;
  };

  @media (min-width: 1200px) {
    max-width: 1140px;
  };

  @media (min-width: 1400px)  {
    max-width: 1320px;

  };
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.card-img {
  width: 100%; /* or any custom size */
  height: 100%; 
  object-fit: contain;
}

.app-body {
  overflow: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 64px;
}

.sm-show {
  display:block;

  @media (min-width: 768px) {
    display:none;
  }

}

.sm-hide {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }

}

.form-control {
    // File input buttons theming
    &::file-selector-button {
      pointer-events: none;
      border-color: inherit;
      border-style: solid;
      border-width: 0;
      border-radius: 0; // stylelint-disable-line property-disallowed-list
    }
}

.d-block{
  display:block;
}

.signed-in-container .user-email {
  margin-top:inherit !important;
}
